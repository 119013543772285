
import { formatDate, formatPersonName, formatPhoneNumber, formatTime, properCase, withDefault } from '@/components/shared/utils';
import VerticalField from '@/components/shared/VerticalField.vue';
import FaxRequestStatusIcon from '@/components/visit/clinical-summary/FaxRequestStatusIcon.vue';
import { useFeatureStore } from '@/stores/FeatureStore';
import { useVisitStore } from '@/stores/VisitStore';
import { ClinicalDocument, FaxRequest } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'ClinicalSummarySentListItem',
    components: {
        VerticalField,
        FaxRequestStatusIcon,
    },
    props: {
        clinicalSummary: {
            type: Object as PropType<ClinicalDocument>,
            required: true,
        },
    },
    data: () => ({
        featureStore: useFeatureStore(),
        visitStore: useVisitStore(),
    }),
    computed: {
        fax() {
            // faxes to be sorted server side, with most recent first
            // return blank object to prevent parsing error in tests when using optional chaining operator
            const fax = this.clinicalSummary?.fax;
            return fax ?? ({} as unknown as FaxRequest);
        },
        isPayerGatewayFeatureEnabled(): boolean {
            return this.featureStore.isEnabled('PAYER_GATEWAY_API');
        },
        isFromPayerPortal(): boolean {
            return this.clinicalSummary.isFromPayerPortal ?? false;
        },
        sendMethod(): string {
            return this.isFromPayerPortal ? 'Payer Portal' : 'eFax';
        },
        sentTo(): string {
            return this.isFromPayerPortal ? withDefault('') : formatPhoneNumber(this.fax.faxNumber);
        },
        payerName(): string | undefined {
            return this.visitStore.visit?.primaryPayer?.name;
        },
    },
    methods: {
        formatDate,
        formatTime,
        formatPersonName,
        formatPhoneNumber,
        properCase,
        withDefault,
        select() {
            this.$router.push({
                name: 'clinicalsummarylistopen',
                params: { id: this.$route.params.id, clinicalSummaryId: this.clinicalSummary.id },
            });
        },
    },
});
