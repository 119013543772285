
import FeatureToggle from '@/components/shared/FeatureToggle.vue';
import { formatDate, formatPersonName, formatPhoneNumber, formatTime, withDefault } from '@/components/shared/utils';
import VerticalField from '@/components/shared/VerticalField.vue';
import FaxRequestStatusIcon from '@/components/visit/clinical-summary/FaxRequestStatusIcon.vue';
import { reSendClinicalDocument } from '@/shared/mutations';
import { useFeatureStore } from '@/stores/FeatureStore';
import { useVisitStore } from '@/stores/VisitStore';
import { ClinicalDocument, FaxRequest, Mutation } from 'generated/graphql/graphql';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'ClinicalSummarySentViewCard',
    components: { FaxRequestStatusIcon, FeatureToggle, VerticalField },
    props: {
        clinicalSummary: {
            type: Object as PropType<ClinicalDocument>,
            required: true,
        },
        disableRetryFaxButton: {
            type: Boolean,
            required: true,
        },
    },
    data: () => ({
        featureStore: useFeatureStore(),
        visitStore: useVisitStore(),
    }),
    computed: {
        fax() {
            return this.clinicalSummary.fax ?? ({} as FaxRequest);
        },
        isPayerGatewayFeatureEnabled(): boolean {
            return this.featureStore.isEnabled('PAYER_GATEWAY_API');
        },
        isFromPayerPortal(): boolean {
            return this.clinicalSummary.isFromPayerPortal ?? false;
        },
        sendMethod(): string {
            return this.isFromPayerPortal ? 'Payer Portal' : 'eFax';
        },
        sentTo(): string {
            return this.isFromPayerPortal ? withDefault('') : formatPhoneNumber(this.fax.faxNumber);
        },
        payerName(): string | undefined {
            return this.visitStore.visit?.primaryPayer?.name;
        },
    },
    methods: {
        formatDate,
        formatTime,
        formatPersonName,
        formatPhoneNumber,
        async retryFax() {
            await this.$apollo.mutate<Mutation>({
                mutation: reSendClinicalDocument,
                variables: {
                    input: {
                        visitId: +this.$route.params.id,
                        clinicalDocumentId: +this.clinicalSummary.id,
                    },
                },
            });
            this.$emit('update', true);
            this.$toast.success('Your clinical summary has been sent!');
        },
    },
});
